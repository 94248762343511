import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import {NgxPaginationModule} from 'ngx-pagination';

import fr from '@angular/common/locales/fr';
import {  APP_BASE_HREF, LocationStrategy, HashLocationStrategy,registerLocaleData } from '@angular/common'; // POUR DEPLOIMENT
registerLocaleData(fr);

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HttpModule } from '@angular/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import * as firebase from 'firebase' ;
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewAnnoncesComponent } from './pages/new-annonces/new-annonces.component';


// Your web app's Firebase configuration
var firebaseConfig = {

  apiKey: "AIzaSyCCkP2_TNIBt7FewebS1fqF_xUW2ueApts",
  authDomain: "immo-test-ed37a.firebaseapp.com",
  databaseURL: "https://immo-test-ed37a.firebaseio.com",
  projectId: "immo-test-ed37a",
  storageBucket: "immo-test-ed37a.appspot.com",
  messagingSenderId: "318684278010",
  appId: "1:318684278010:web:29be2884d2ea979f872d43"
/*
apiKey: "AIzaSyCJIBgrAD9X9lkfpSPVYINnZvxAlOQeimc",
authDomain: "afrique-immo.firebaseapp.com",
databaseURL: "https://afrique-immo.firebaseio.com",
projectId: "afrique-immo",
storageBucket: "afrique-immo.appspot.com",
messagingSenderId: "75185448291",
appId: "1:75185448291:web:b00c30b6bece733f"
*/
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    NewAnnoncesComponent
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    ReactiveFormsModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    SharedModule,
    NgxPaginationModule,
    HttpClientModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCERobClkCv1U4mDijGm1FShKva_nxsGJY"
    }),
    PerfectScrollbarModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },// POUR DÉPLOIMENT
    { provide: LocationStrategy, useClass: HashLocationStrategy },// POUR DÉPLOIMENT
    {provide:LOCALE_ID,useValue:"fr-FR"},
    AuthService,
    AuthGuard,
    AngularFireAuth,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
