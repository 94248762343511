import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase';

@Injectable()
export class AuthService {
  token: string;

  constructor(private toastr : ToastrService,private router : Router,) {}

  connectUser(email: string, password: string){
    return new Promise(
      (resolve, reject)=>{
        firebase.auth().signInWithEmailAndPassword(email,password).then(
          (authData: any) =>{ 
            if(authData.user.emailVerified){
              localStorage.setItem('adminUID', authData.user.uid);
              resolve();
            }else{
              this.toastr.error('Oups désolé, svp veuillez confirmer votre compte avec le lien réçu depuis votre adresse email','Vérification email',{
                timeOut: 10000, 
                progressBar:true, 
            });
              this.router.navigate(['/pages/login']);
            }
          },
          (error ) =>{
            reject(error);
          }
        );
      }
    );
 }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {   
    //this.token = null;
    firebase.auth().signOut();
    firebase.auth().signOut().then(() => {
         localStorage.removeItem('adminUID');
         this.router.navigate(['/pages/login']);
     });
  }

  getToken() {    
    return this.token;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    return true;
  }
}
