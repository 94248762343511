import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    {
        path: '/dashboard/dashboard1', title: 'Tableau de Bord', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
    },

    {
        path: '/tables/extended', title: 'Annonces Publiées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/annonces-attente', title: 'Annonces En Attente', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/annonces-annule', title: 'Annonces à Annuler', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/demandes-valide', title: 'Demandes Publiées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/demandes-attente', title: 'Demandes En Attente', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/demandes-annule', title: 'Demandes à Annuler', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },


    {
        path: '/tables/projects-valide', title: 'Lotissements Publiées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/projects-attente', title: 'Lotissements En Attente', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/projects-annule', title: 'Lotissements à Annuler', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },


    {
        path: '/tables/savoir-valide', title: 'Bon à savoir Publiées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/savoir-attente', title: 'Bon à savoir En Attente', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/savoir-annule', title: 'Bon à savoir à Annuler', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },


    {
        path: '/tables/annonces-categories', title: 'Catégories', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/commandes', title: 'Commandes', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/utilisateurs', title: 'Utilisateurs', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/newsletters', title: 'Newsletters', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },

    {
        path: '/tables/offre-vip', title: 'Offres VIP', icon: 'ft-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/contacts', title: 'Contacts', icon: 'ft-phone', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/partenaires', title: 'Partenaires', icon: 'ft-thumbs-up', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/slides', title: 'Slides', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/services', title: 'Services', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/realisations', title: 'Réalisations', icon: 'ft-check-square', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/annonces-delete', title: 'Annonces Annulées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/demandes-delete', title: 'Demandes Annulées', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/projects-delete', title: 'Lotissements Annulés', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/tables/savoir-delete', title: 'Bon à savoir Annulés', icon: 'ft-bookmark', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    }
];
