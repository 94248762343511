import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-annonces',
  templateUrl: './new-annonces.component.html',
  styleUrls: ['./new-annonces.component.scss']
})
export class NewAnnoncesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
