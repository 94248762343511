import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router : Router, private toastr : ToastrService,public authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated();
  }
  
  /*canActivate() : Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve,reject)=>{
        firebase.auth().onAuthStateChanged(
          (user)=>{
            this.router.navigate(['/pages/login']);
            //
            if(user){
            resolve(true);
            }else{
            this.toastr.error('Accès refusé, veuillez svp vous connecter avant d\' accéder à cette ressource','Autorisation',{
              timeOut: 10000,
              progressBar: true,
            });
            this.router.navigate(['/pages/login']);
            resolve(false);
            }
          }
        );
      }
    );
  }*/
}
