// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebaseConfig :{
    apiKey: "AIzaSyCCkP2_TNIBt7FewebS1fqF_xUW2ueApts",
    authDomain: "immo-test-ed37a.firebaseapp.com",
    databaseURL: "https://immo-test-ed37a.firebaseio.com",
    projectId: "immo-test-ed37a",
    storageBucket: "immo-test-ed37a.appspot.com",
    messagingSenderId: "318684278010",
    appId: "1:318684278010:web:29be2884d2ea979f872d43"

/*
apiKey: "AIzaSyCJIBgrAD9X9lkfpSPVYINnZvxAlOQeimc",
authDomain: "afrique-immo.firebaseapp.com",
databaseURL: "https://afrique-immo.firebaseio.com",
projectId: "afrique-immo",
storageBucket: "afrique-immo.appspot.com",
messagingSenderId: "75185448291",
appId: "1:75185448291:web:b00c30b6bece733f"



*/
}
};
